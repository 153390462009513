@import url(https://fonts.googleapis.com/css?family=Josefin+Sans);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.pagination {
    margin-top: 20px;
  }

  .bucketUploader {
    /* font-family: 'Josefin Sans',Impact,Arial, Helvetica, sans-serif; */
    font-family:'Montserrat','Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #2c3e50;
    font-size: 1.1rem;
    font-weight :500;
    padding: 10px 20px;
    background-color: white;
  }
  .cautionText {
    font-size:small;
    color: #EA5D5D;
  }
  /* .previewtable {
    font-size: 0.9rem;
  } */

  h3 {
    font-weight :500
  }
  .file-uploader-container {
    /* padding: 20px; */
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .file-uploader-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10;
    padding-bottom: 20px;
  }
  
  .file-uploader-header h3 {
    margin-bottom: 5px;
  }
  
  .preview-title {
    text-align: center;
    margin-top: 20px;
    position: -webkit-sticky;
    position: sticky;
    top: 120px;
    background-color: white;
    z-index: 10;
    padding-bottom: 10px;
  }
  
  .previewtable {
    max-height: 300px;
    overflow-y: auto;
    /* margin-top: 20px; */
    border: 1px solid #ddd;
    font-size: 0.9rem;
  }
  
  .previewtable table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .previewtable th, .previewtable td {
    text-align: left;
    padding: 10px;
  }

  .previewtable::-webkit-scrollbar {
    width: 5px;
  }
  
  .previewtable::-webkit-scrollbar-thumb {
    background-color: #888; /* Scrollbar color */
    border-radius: 10px;
  }
  
  .previewtable::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Darker on hover */
  }
  
  .previewtable::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Track color */
  }
  
  
